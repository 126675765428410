import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Router } from '@angular/router';
import { StorageKey } from '../_models/local-storage-key';
import { GenericInputDataModel } from '../shared-components/models/generic.interface';
import {
	CampaignRewardCommissionLengthTypeEnum,
	CampaignRewardTypeEnum
} from '../social-media-influencer/modules/campaign-create-v3/models/campaign-reward.model';

export interface ScrollOptions {
	behavior?: string;
	block?: string;
	inline?: string;
}

export class UtilsService {
	public static BOOK_DEMO_URL: string = 'https://calendly.com/lolly-com/book-a-demo';
	public static LANDING_PAGE_KB_VIDEO_URL: string = 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/lolly-videos/lolly+Overview.mp4';
	public static DISCOVERY_KB_VIDEO_URL: string = 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/lolly-videos/Discovery.mp4';
	public static DISCOVERY_LIST_KB_VIDEO_URL: string =
		'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/lolly-videos/Library+_+Loom+-+20+June+2024.mp4';
	public static CAMPAIGN_KB_VIDEO_URL: string = 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/lolly-videos/Campaigns.mp4';
	public static ZOHO_PAYPAL_KB_LINK: string = 'https://lolly.zohodesk.in/portal/en/kb/articles/set-up-paypal';

	public static googleTagAsString: string = `
		<!-- Google tag (gtag.js) -->
		<script async src="https://www.googletagmanager.com/gtag/js?id=AW-706887471"></script>
		<script>
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'AW-706887471');
		</script>
	`;

	public static getPixelManualCode(client_id: string) {
		return `
			<!-- Pixel tracking js -->
			<script async src="https://s3-eu-west-1.amazonaws.com/bucket-influencer-service-danish/pixel/bigcommerce_pixel_main.js?id=${client_id}&v=${new Date().getFullYear()}-${String(
			new Date().getMonth() + 1
		).padStart(2, '0')}-${Math.ceil((new Date().getDate() + 6) / 7)}"></script>
			<script>
				window.pixlDataWrapper = window.pixlDataWrapper || [];
				function lmark(){pixlDataWrapper.push(arguments);}
				lmark('js', new Date());
				lmark('config', '${client_id}');
			</script>
		`;
	}

	public static listsAreEquivalent(list1: any[], list2: any[], uniqueKeyMatcher?: string): boolean {
		if (!list1 || !list2 || list1.length !== list2.length) {
			return false;
		}

		// for lists that contains objects [{...}, {...}] <=> [{...}, [{...}]]
		if (uniqueKeyMatcher) {
			const list1HasDuplicates = this.listHasDuplicates(list1.map(item => item[uniqueKeyMatcher]));
			const list2HasDuplicates = this.listHasDuplicates(list2.map(item => item[uniqueKeyMatcher]));

			if (list1HasDuplicates || list2HasDuplicates) {
				console.error('The provided lists contains duplicates');
				return false;
			}

			const status = !list1.some(item1 => {
				const match = list2.some(item2 => item2[uniqueKeyMatcher] === item1[uniqueKeyMatcher]);
				return !match;
			});

			return status;
		} else {
			// for lists that contains primitives ['a', 'b'] <=> ['b', 'a']
			return !list1.some(entry1 => !(list2.indexOf(entry1) !== -1));
		}
	}

	public static listHasDuplicates(list: any[]): boolean {
		return new Set(list).size !== list.length;
	}

	/**
	 * scrollToElement()
	 * @param selectorOrElement: string | HTMLElement
	 * @param options: ScrollOptions | boolean
	 * @param scrollDelay: number ( delay in milliseconds )
	 */
	public static scrollToElement(selectorOrElement: string | HTMLElement, options?: ScrollOptions | boolean, scrollDelay?: number): void {
		let element: any = selectorOrElement;
		if (typeof selectorOrElement === 'string') {
			element = document.querySelector(selectorOrElement);
		}
		scrollDelay ? setTimeout(() => element.scrollIntoView(options), scrollDelay) : element.scrollIntoView(options);
	}

	public static findFirstInvalidFormControl(formGroup: UntypedFormGroup): string {
		let firstInvalidFormControlSelector: string; // ex: campaignNameControl
		const formControls = Object.keys(formGroup.controls);
		for (let i = 0; i < formControls.length; i++) {
			const propertyName = formControls[i];
			const formControlValue = formGroup.controls[propertyName];
			if (formControlValue.invalid) {
				firstInvalidFormControlSelector = propertyName;
				break;
			}
		}
		return firstInvalidFormControlSelector;
	}

	public static ngbDateToMoment(ngbDate: NgbDate): moment.Moment {
		return moment(new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day).getTime());
	}

	public static ngbDateToTime(ngbDate: NgbDate): number {
		return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day).getTime();
	}

	public static momentToNgb(momentDate: moment.Moment): NgbDate {
		return new NgbDate(momentDate.year(), momentDate.month() + 1, momentDate.date()); // "+1" = month index 0 = Jan
	}

	public static objectIsEmpty(obj: any): boolean {
		for (const x in obj) {
			if (obj.hasOwnProperty(x)) {
				return false;
			}
		}
		return true;
	}

	public static removeEmptyProperties(obj: any, leaveNullAndEmptyLists: boolean = false): void {
		Object.keys(obj).forEach(item => {
			const currentObj = obj[item];
			if (
				!currentObj ||
				(typeof currentObj === 'object' && this.objectIsEmpty(currentObj)) ||
				(Array.isArray(currentObj) && (leaveNullAndEmptyLists || currentObj.length === 0))
			) {
				if (!leaveNullAndEmptyLists || currentObj !== null) {
					delete obj[item];
				}
			}
		});
	}

	public static noWhitespaceValidator(control: UntypedFormControl) {
		return (control.value || '').trim().length ? null : { whitespace: true };
	}

	public static noWhitespaceAtStartValidator(control: UntypedFormControl) {
		let isWhitespace = (control.value || '').trim().length === 0;
		let isValid = !isWhitespace;
		return isValid ? null : { whitespace: true };
	}

	public static firstCharacterAlphabet(control: UntypedFormControl) {
		if (typeof control.value === 'string') {
			let value = control.value || '';
			const regex = /^[A-Za-z]/;
			const isValid = regex.test(value);
			return isValid ? null : { first_char_invalid: true };
		}
		return null;
	}

	/*
	 *
	 *  INPUT
	 *
	 *  someObject = {
	 *     something: {
	 *       else: {
	 *         name: 'some string',
	 *         value: 100
	 *       }
	 *     }
	 *  }
	 *
	 *  OUTPUT
	 *
	 *  {
	 *    something.else.name: 'some string',
	 *    something.else.value: 100
	 *  }
	 *
	 * */
	public static flattenObject(obj: any): any {
		const result: any = {};

		function recurse(cur: any, prop: any) {
			if (Object(cur) !== cur) {
				result[prop] = cur;
			} else if (Array.isArray(cur)) {
				for (let i = 0; i < cur.length; i++) {
					recurse(cur[i], prop + '[' + i + ']');
				}
				if (cur.length == 0) {
					result[prop] = [];
				}
			} else {
				let isEmpty = true;
				for (const p in cur) {
					isEmpty = false;
					recurse(cur[p], prop ? prop + '.' + p : p);
				}
				if (isEmpty && prop) {
					result[prop] = {};
				}
			}
		}

		recurse(obj, '');
		return result;
	}

	public static unflattenObject(object: any): any {
		if (Object(object) !== object || Array.isArray(object)) {
			return object;
		}

		const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
		const resultHolder: any = {};

		for (const p in object) {
			let cur: any = resultHolder;
			let prop = '';
			let m;

			while ((m = regex.exec(p))) {
				cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
				prop = m[2] || m[1];
			}
			cur[prop] = object[p];
		}
		return resultHolder[''] || resultHolder;
	}

	public static validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	public static routeIs(routeUrl: string, routerInstance: Router): boolean {
		if (!routeUrl || !routerInstance) {
			console.warn('Provide a router url and routerInstance');
			return false;
		}

		return routerInstance.url.startsWith(`/${routeUrl}`);
	}

	public static enumToArray(enumme: any) {
		const map = [];

		for (const n in enumme) {
			isNaN(Number(n)) && map.push({ id: enumme[n], name: n });
		}

		return map;
	}

	public static downloadUrlFile(fileUrl: string): void {
		window.open(fileUrl, '_self');
	}

	public static abbreviateNumber(value: number): string {
		if (!value) {
			return '0';
		}
		var newValue: string | number = value;
		if (value >= 1000) {
			var suffixes = ['', 'k', 'm', 'b', 't'];
			var suffixNum = Math.floor(('' + value).length / 3);
			var shortValue;
			for (var precision = 2; precision >= 1; precision--) {
				shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
				var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
				if (dotLessShortValue.length <= 2) {
					break;
				}
			}
			if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
			newValue = shortValue + suffixes[suffixNum];
		}
		return newValue.toString();
	}
}

export const toFixed = (num: number, fix: number): number => {
	return Number(num.toFixed(fix).toString());
};

export const getPercentage = (value = 0, total = 0): number => {
	return (value / total) * 100;
};

export const updateToken = (token): void => {
	localStorage.setItem(StorageKey.token, token);
};

export const updateDecodedToken = (values): void => {
	const oldDecodedToken = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
	let newToken = {
		...oldDecodedToken,
		...values
	};
	localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(newToken));
};

export const getToken = (): any => {
	return localStorage.getItem(StorageKey.token);
};

export const getDecodedToken = (): any => {
	return JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
};

export const getFormData = (payload): FormData => {
	const formData = new FormData();
	for (let key in payload) formData.append(key, payload[key]);
	return formData;
};

export const exportFile = (blob, name): void => {
	const a = document.createElement('a');
	const objectUrl = URL.createObjectURL(blob);
	a.href = objectUrl;
	a.download = name || 'file.csv';
	a.click();
	URL.revokeObjectURL(objectUrl);
};

export const formatDate = (date): string => {
	if (date) return new Date(date).toISOString();
	else return null;
};

export const getInitials = (name: string): string => {
	return name
		.split(' ')
		.slice(0, 2)
		.map(i => i.charAt(0).toUpperCase())
		.join('');
};

export const getStripePlanType = (productName: string): string => {
	const regex = /\b(starter|business|teams|standard|professional|basic)\b/i;
	const match = productName.match(regex);

	if (match) {
		return match[0].toLowerCase();
	} else {
		return null; // Return null if no match is found
	}
};

export const getSizeInMb = (sizeInBytes: number): string => {
	let sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
	return sizeInMB;
};

export const objToQueryString = (obj: any) => {
	if (typeof obj !== 'object' || Array.isArray(obj)) {
		throw new Error('Input must be an object');
	}

	let queryString = '';
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];
			if (value !== null && value !== undefined) {
				if (Array.isArray(value)) {
					queryString += value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
				} else {
					queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
				}
				queryString += '&';
			}
		}
	}

	if (queryString) queryString = '?' + queryString;

	return queryString.slice(0, -1); // Remove the last '&' character
};

export function scrollParentToChild(parent, child) {
	var parentRect = parent.getBoundingClientRect();
	var parentViewableArea = {
		height: parent.clientHeight,
		width: parent.clientWidth
	};

	var childRect = child.getBoundingClientRect();
	var isViewable = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parentViewableArea.height;

	if (!isViewable) {
		const scrollTop = childRect.top - parentRect.top;
		const scrollBot = childRect.bottom - parentRect.bottom;
		if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
			parent.scrollTo({ top: parent.scrollTop + scrollTop, behavior: 'smooth' });
			//   parent.scrollTop += scrollTop;
		} else {
			parent.scrollTo({ top: parent.scrollTop + scrollBot, behavior: 'smooth' });
			//   parent.scrollTop += scrollBot;
		}
	}
}

export function autocompleteObjectValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (typeof control.value === 'string') {
			return { invalidAutocompleteObject: { value: control.value } };
		}
		return null; /* valid option selected */
	};
}

export function arrayNotEmptyValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const value = control.value;

		// Check if the value is null or an empty array
		if (value === null || (Array.isArray(value) && value.length === 0)) {
			return { arrayEmpty: true };
		}

		return null;
	};
}

export const URL_REGEXP = /^(?!http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const TEXT_OVERFLOW_ELLIPSIS_STYLES = {
	overflow: 'hidden',
	'white-space': 'nowrap',
	'text-overflow': 'ellipsis'
};

export const timeUnits: GenericInputDataModel[] = [
	{
		id: 1,
		name: 'Days',
		value: 'days'
	},
	{
		id: 2,
		name: 'Hours',
		value: 'hours'
	},
	{
		id: 3,
		name: 'Minutes',
		value: 'minutes'
	}
];

export const SUPPORTED_IMAGE_FILES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/bmp', 'image/webp'];

export const SUPPORTED_DOCUMENT_FILES = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'text/plain',
	'text/csv',
	'application/rtf'
];
